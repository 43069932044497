import {
    Controller
} from "stimulus"
var listOfCounties = [
    ['Carlow', 'Ceatharlach', 'Contae Cheatharlach', 'County Carlow', 'Co. Carlow'],
    ['Cavan', 'An Cabhán', 'An Cabhan', 'Contae an Chabháin', 'Contae an Chabhain', 'County Cavan', 'Co. Cavan'],
    ['Clare', 'An Clár', 'An Clar', 'Contae an Chláir', 'Contae an Chlair', 'County Clare', 'Co. Clare'],
    ['Cork', 'Corcaigh', 'Contae Chorcaí', 'Contae Chorcai', 'County Cork', 'Co. Cork'],
    ['Donegal', 'Dún na nGall', 'Dun na nGall', 'Contae Dhún na nGall', 'Contae Dhun na nGall', 'County Donegal', 'Co. Donegal'],
    ['Dublin', 'Baile Átha Cliath', 'Baile Atha Cliath', 'County Dublin', 'Co. Dublin'],
    ['Galway', 'Gaillimh', 'Contae na Gaillimhe', 'County Galway', 'Co. Galway'],
    ['Kerry', 'Ciarraí', 'Ciarrai', 'Contae Chiarraí', 'Contae Chiarrai', 'County Kerry', 'Co. Kerry'],
    ['Kildare', 'Cill Dara', 'Contae Chill Dara', 'County Kildare', 'Co. Kildare'],
    ['Kilkenny', 'Cill Chainnigh', 'Contae Chill Chainnigh', 'County Kilkenny', 'Co. Kilkenny'],
    ['Laois', 'Contae Laoise', 'County Laois', 'Co. Laois'],
    ['Leitrim', 'Liatroim', 'Contae Liatroma', 'County Leitrim', 'Co. Leitrim'],
    ['Limerick', 'Luimneach', 'Contae Luimnigh', 'County Limerick', 'Co. Limerick'],
    ['Longford', 'An Longfort', 'Contae an Longfoirt', 'County Longford', 'Co. Longford'],
    ['Louth', 'Lú', 'Lu', 'Contae Lú', 'Contae Lu', 'County Louth', 'Co. Louth'],
    ['Mayo', 'Maigh Eo', 'Contae Mhaigh Eo', 'County Mayo', 'Co. Mayo'],
    ['Meath', 'An Mhí', 'An Mhi', 'Contae na Mí', 'Contae na Mi', 'County Meath', 'Co. Meath'],
    ['Monaghan', 'Muineachán', 'Muineachan', 'Contae Mhuineacháin', 'Contae Mhuineachain', 'County Monaghan', 'Co. Monaghan'],
    ['Offaly', 'Uíbh Fhailí', 'Uibh Fhaili', 'Contae Uíbh Fhailí', 'Contae Uibh Fhaili', 'County Offaly', 'Co. Offaly'],
    ['Roscommon', 'Ros Comáin', 'Ros Comain', 'Contae Ros Comáin', 'Contae Ros Comain', 'County Roscommon', 'Co. Roscommon'],
    ['Sligo', 'Sligeach', 'Contae Shligigh', 'County Sligo', 'Co. Sligo'],
    ['Tipperary', 'Tiobraid Árann', 'Tiobraid Arann', 'Contae Thiobraid Árann', 'Contae Thiobraid Arann', 'County Tipperary', 'Co. Tipperary'],
    ['Waterford', 'Port Láirge', 'Port Lairge', 'Contae Phort Láirge', 'Contae Phort Lairge', 'County Waterford', 'Co. Waterford'],
    ['Westmeath', 'An Iarmhí', 'An Iarmhi', 'Contae na hIarmhí', 'Contae na hIarmhi', 'County Westmeath', 'Co. Westmeath'],
    ['Wexford', 'Loch Garman', 'Contae Loch Garman', 'County Wexford', 'Co. Wexford'],
    ['Wicklow', 'Cill Mhantáin', 'Cill Mhantain', 'Contae Chill Mhantáin', 'Contae Chill Mhantain', 'County Wicklow', 'Co. Wicklow']
];

export default class extends Controller {

    static targets = ["fulfilment_type"]

    connect() {
        this.update_checkout();

    }

    update_checkout(event) {

        this.fulfilment_typeTargets.forEach(target => {

            if (target.checked) {
                target.closest("span").classList.add("bg-blue-200")
            } else {
                target.closest("span").classList.remove("bg-blue-200")
            }

        })

    }

    initialize() {

        var isDelivery = document.getElementById('is_delivery')?.value == "true";
        var addressSplittingEnabled = document.getElementById('address_splitting_enabled')?.value == "true";

        if(!isDelivery || !addressSplittingEnabled)
            return;

            if (document.getElementById('order_street')?.value != '' ||
                document.getElementById('order_street_number')?.value != '' ||
                document.getElementById('order_county')?.value != '' ||
                document.getElementById('order_postcode')?.value != '' ||
                document.getElementById('order_locality')?.value != '') {
                this.showAddressItems();
            }
            setTimeout(() => {
                this.initMap();
            }, 1000);
            setTimeout(() => {
                document.getElementById('order_address_temp').setAttribute("autocomplete", "chrome-off");
            }, 1500);
        
    }

    initMap() {


        var input = document.getElementById('order_address_temp');
        if (input != null) {
            var autocomplete = new google.maps.places.Autocomplete(input);
            autocomplete.setTypes(["address"]);
            autocomplete.addListener('place_changed', function() {

                document.getElementById('warning_toastr').style.display = "none";
                document.getElementById('error_toastr').style.display = "none";
                document.getElementById('checkout_submit_button').removeAttribute("disabled");

                var place = autocomplete.getPlace();
                if (!place.geometry) {
                    document.getElementById('error_toastr').style.display = "block";
                    return;
                }
                var address = '';
                if (place.address_components) {
                    address = [
                        (place.address_components[0] && place.address_components[0].short_name || ''),
                        (place.address_components[1] && place.address_components[1].short_name || ''),
                        (place.address_components[2] && place.address_components[2].short_name || '')
                    ].join(' ');
                }

                document.getElementById('order_latitude').value = null;
                document.getElementById('order_longitude').value = null;
                document.getElementById('order_postcode').value = null;
                document.getElementById('order_city').value = null;
                document.getElementById('order_county').value = null;

                // Location details
                var county = "";
                var city = "";
                var locality = "";
                var street = "";
                var country = "";

                for (var i = 0; i < place.address_components.length; i++) {

                    if (place.address_components[i].types[0] == 'street_number') {
                        street = place.address_components[i].long_name;
                        document.getElementById('order_street_number').value = place.address_components[i].long_name;
                    }

                    if (place.address_components[i].types[0] == 'route') 
                        street += street != "" ? " " + place.address_components[i].long_name : place.address_components[i].long_name;
                    
                    if (place.address_components[i].types[0] == 'neighborhood') {
                        locality == "" ? locality += place.address_components[i].long_name : locality += ", " + place.address_components[i].long_name
                        document.getElementById('order_locality').value = locality;
                    }

                    if (place.address_components[i].types[0] == 'administrative_area_level_1')
                        county = place.address_components[i].long_name;

                    if (place.address_components[i].types[0] == 'locality' || place.address_components[i].types[0] == 'postal_town')
                        city = place.address_components[i].long_name

                    if (place.address_components[i].types[0] == 'country')
                        country = place.address_components[i].long_name

                    document.getElementById('order_city').value = city;

                    if (place.address_components[i].types[0] == 'postal_code') {
                        document.getElementById('order_postcode').value = place.address_components[i].long_name;
                    }

                }
                var county = document.getElementById('order_county');
                var countyBox = document.getElementById('county-box');


                if(countyBox != null){
                  if(country == "Ireland"){
                    countyBox.style.display = "block";

                    if(county != null)
                        county.setAttribute("required", "required");
                  }
                  else{
                    countyBox.style.display = "none";
                    if(county != null)
                        county.removeAttribute("required");
                  }
                }

                document.getElementById('order_street').value = street;
                document.getElementById('order_county').value = county;
                document.getElementById('order_country').value = country;


                listOfCounties.forEach((element, index) => {

                    var result = element.includes(county)
                    if (result) {
                        document.getElementById('order_county').value = element[0];
                    }
                });

                document.getElementById('order_address').value = place.formatted_address;
                document.getElementById('order_address_temp').value = "";
                document.getElementById('address-items').style.display = 'block';
                document.getElementById('enter-address-manually').style.display = 'none'

                document.getElementById('order_street').setAttribute("required", "required");
                document.getElementById('order_city').setAttribute("required", "required");
                document.getElementById('order_county').setAttribute("required", "required");
                document.getElementById('order_postcode').setAttribute("required", "required");

                document.getElementById('order_latitude').value = place.geometry.location.lat();
                document.getElementById('order_longitude').value = place.geometry.location.lng();

                var limitDeliveryArea = document.getElementById('limit_delivery_area').value == "true";
                var limidDeliveryAreaEnabled = document.getElementById('delivery_area_enabled')?.value == "true";


                var menuLatitude = document.getElementById('menu_latitude').value
                var menuLongiture = document.getElementById('menu_lngitude').value
                var deliveryArea = document.getElementById('menu_delivery_area').value
                var menuCords = new google.maps.LatLng(menuLatitude, menuLongiture);
                var orderCords = new google.maps.LatLng(place.geometry.location.lat(), place.geometry.location.lng());

                if (limitDeliveryArea && limidDeliveryAreaEnabled) {
                    var distance = google.maps.geometry.spherical.computeDistanceBetween(menuCords, orderCords);
                    distance = (distance / 1000).toFixed(2)
                    if (distance > parseInt(deliveryArea)) {
                        var isCollection = document.getElementById('is_collection').value == "true";

                        if (!isCollection)
                            document.getElementById('warning_toastr').style.display = "block";
                        else
                            document.getElementById('warning_toastr_collection').style.display = "block";

                        document.getElementById('checkout_submit_button').setAttribute("disabled", "true");
                        document.getElementById('order_address_temp').value = place.formatted_address;
                        document.getElementById('order_address_container').style.display = "none";
                    }
                }
            });
        }
    }

    showAddressItems() {
        document.getElementById('address-items').style.display = 'block';
        document.getElementById('address-items').style.marginTop = '-10px';

        document.getElementById('enter-address-manually').style.display = 'none'
        document.getElementById('order_address_temp').style.display = 'none'
        document.getElementById('address_temp_label').style.display = 'none'

        document.getElementById('order_street').setAttribute("required", "required");
        document.getElementById('order_city').setAttribute("required", "required");
        document.getElementById('order_county').setAttribute("required", "required");
        document.getElementById('order_postcode').setAttribute("required", "required");

    }

    hideFields(e){
        
        var county = document.getElementById('order_county');

        if(document.getElementById('order_country').value == "Ireland"){
            document.getElementById('county-box').style.display = "block";
            if(county != null){
                county.setAttribute("required", "required");
            }
        }
        else{
            document.getElementById('county-box').style.display = "none";
            if(county != null){
                county.removeAttribute("required");
            }
        }
    }

    validateAddress(e) {

        var isDelivery = document.getElementById('is_delivery')?.value == "true";
        var limidDeliveryAreaEnabled = document.getElementById('delivery_area_enabled')?.value == "true";
        var limitDeliveryArea = document.getElementById('limit_delivery_area')?.value  == "true";

        if (!isDelivery || !limidDeliveryAreaEnabled || !limitDeliveryArea)
            return;

        var formValid = this.validateForm()
        if(formValid)
            e.preventDefault();

        this.hideAllWarnings();

        var address = document.getElementById('order_street').value + " " + document.getElementById('order_locality').value + " " + document.getElementById('order_city').value + " " + document.getElementById('order_county').value + " " + " " + document.getElementById('order_postcode').value; + " " + document.getElementById('order_country').value;
        document.getElementById('order_address').setAttribute('value', address);
        
        var geocode = new google.maps.Geocoder();
        geocode.geocode({address: address }, function(results, status) {

            if (status == google.maps.GeocoderStatus.OK) {
                    var menuLatitude = document.getElementById('menu_latitude').value
                    var menuLongiture = document.getElementById('menu_lngitude').value
                    var deliveryArea = document.getElementById('menu_delivery_area').value
                    var menuCords = new google.maps.LatLng(menuLatitude, menuLongiture);
                    var isCollection = document.getElementById('is_collection').value == "true";

                    if (results.length > 0) {
                        var distance = google.maps.geometry.spherical.computeDistanceBetween(menuCords, results[0].geometry.location);
                        distance = (distance / 1000).toFixed(2)
                        if (distance > parseInt(deliveryArea)) {
                            e.preventDefault();  

                            if (!isCollection)
                                document.getElementById('warning_toastr').style.display = "block";
                            else
                                document.getElementById('warning_toastr_collection').style.display = "block";
                        
                        }
                        else{
                            if(formValid)
                              document.getElementById("checkout_form").submit();
                        }

                    } else {
                        e.preventDefault();

                        if (!isCollection)
                            document.getElementById('warning_toastr').style.display = "block";
                        else
                            document.getElementById('warning_toastr_collection').style.display = "block";

                    }

                } else {
                    e.preventDefault();
                    document.getElementById('error_toastr').style.display = "block";
                }
            });
    }

    hideAllWarnings() {
        document.getElementById('error_toastr').style.display = "none";
        document.getElementById('warning_toastr_collection').style.display = "none";
        document.getElementById('warning_toastr').style.display = "none";
    }

    validateForm() {
        let isValid = true;
        var form = document.getElementById("checkout_form");
        let requiredFieldSelectors = 'textarea:required, input:required, select:required';
        let requiredFields = form.querySelectorAll(requiredFieldSelectors);
    
        requiredFields.forEach((field) => {
          if (!field.disabled && !field.value.trim()) {
            isValid = false;

            return false;
          }
        });
        
        // If we already know we're invalid, just return false
        if (!isValid) {
          return false;
        }
    
        // Search for any browser invalidated input fields and focus them
        let invalidFields = form.querySelectorAll('input:invalid');
        
        invalidFields.forEach((field) => {
          if (!field.disabled) {

            // field.focus();
            
            isValid = false;
          }
        });
        
        return isValid;
      }




}