/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Rails functionality
window.Rails = require("@rails/ujs")
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("trix")
require("@rails/actiontext")

// Tailwind CSS
import "stylesheets/application"

// Stimulus controllers
import "controllers"

// Jumpstart Pro & other Functionality
import "src/actiontext"
import "src/confirm"
import "src/direct_uploads"
import "src/forms"
import "src/timezone"
import "src/tooltips"
//import "src/cookie-message"




import LocalTime from "local-time"
LocalTime.start()

// ADD YOUR JAVACSRIPT HERE
var reloadWithTurbolinks = (function () {
  var scrollPosition

  function reload () {
    scrollPosition = [window.scrollX, window.scrollY]
    //Turbolinks.clearCache()
    Turbolinks.visit(window.location.toString(), { action: 'replace' })
  }

  document.addEventListener('turbolinks:load', function () {
    if (scrollPosition) {
      window.scrollTo.apply(window, scrollPosition)
      scrollPosition = null
    }
  })

  return reload
})()


// document.addEventListener('turbolinks:load', function () {
//
// 	if (document.getElementsByTagName("body")[0].getAttribute("data-refresh")) {
// 	    // data attribute doesn't exist
// 	    setInterval(function () {
// 	      reloadWithTurbolinks()
// 	    }, 60000)
// 	}
//
// })



import CodeMirror from "codemirror"

document.addEventListener('turbolinks:load', function () {
	
	// document.getElementById("code")
	
	function editor(id)
	{
		var element = document.getElementById(id)
		
		if (element){
		    var editor = CodeMirror.fromTextArea(element, {
				// height: "350px",
				// parserfile: "parsexml.js",
				// stylesheet: "css/xmlcolors.css",
				// path: "js/",
				// continuousScanning: 500,
				// lineNumbers: true
				lineNumbers: true,
				styleActiveLine: true,
				matchBrackets: true,
				theme: "ayu-dark" //,
				// extraKeys: {
				//   "F11": function(cm) {
				//     cm.setOption("fullScreen", !cm.getOption("fullScreen"));
				//   },
				//   "Esc": function(cm) {
				//     if (cm.getOption("fullScreen")) cm.setOption("fullScreen", false);
				//   }
				// }
		    });
		}
		
	}
	
	editor('code1');
	editor('code2');
	
})


// Start Rails UJS
Rails.start()

/*
import CookiesEuBanner from "cookies-eu-banner"

document.addEventListener("turbolinks:load", () => {
	
	var cookiesBanner = new CookiesEuBanner(function () {
	    console.log("LOAD GA");
	}, true);

	// Prefer use addEventListener
	// For demo purpose I will use the CookiesEuBanner internal method
	cookiesBanner.addClickListener(document.getElementById('custom-accept'), function () {
	  cookiesBanner.setConsent(true);
	  cookiesBanner.removeBanner();
	  updateCookiesEuState();
	});

	cookiesBanner.addClickListener(document.getElementById('custom-reject'), function () {
	  cookiesBanner.setConsent(false);
	  cookiesBanner.removeBanner();
	  updateCookiesEuState();
	});

	cookiesBanner.addClickListener(document.getElementById('custom-reset'), function () {
	  cookiesBanner.deleteCookie(cookiesBanner.cookieName);
	  window.location.reload();
	});
	
})
*/

import 'alpinejs'
