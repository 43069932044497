import { Controller } from 'stimulus'
var map = null;
var distanceWidget = "";
var radiusWidget = "";
var listener = null;
export default class extends Controller {

  static targets = ['tab', 'panel']

  connect() {
    this.activeTabClasses = (this.data.get('activeTab') || 'active').split(' ')
    this.inactiveTabClasses = (this.data.get('inactiveTab') || 'inactive').split(' ')
    this.disable_inputs_scrool();
  }

  disable_inputs_scrool(e) {
    var inputs = document.querySelectorAll('input[type="number"]');
    inputs.forEach(function (element) {
      element.parentElement.addEventListener("wheel", (e) => {
        if (e.target.nodeName == "INPUT")
          e.preventDefault();
      })
    });

  }

  initialize() {

    var limit_delivery_area = document.getElementById('menu_limit_delivery_area');
    var limit_delivery_area_container = document.getElementById('delivery_area_container');

   var isDelivery = document.getElementById('menu_delivery')?.checked;
   var isDispatch = document.getElementById('menu_dispatch')?.checked;

    this.initPlacesSearch();

    if (limit_delivery_area_container != null) {
      if (isDelivery == true || isDispatch == true)
        limit_delivery_area_container.style.display = "block";
      else
        limit_delivery_area_container.style.display = "none";
    }

    if(document.getElementById('menu_is_custom_fulfilment')?.checked){
      this.disableFulfilmentsExcept(true, "menu_is_custom_fulfilment");
    Array.from(document.getElementsByClassName("custom_fulfilment-element")).forEach(element => element.style.display = "block");
    }
    else{
      this.disableFulfilmentsExcept(false, "menu_is_custom_fulfilment");
      Array.from(document.getElementsByClassName("custom_fee-element")).forEach(element => element.style.display = "none");
    }

    if (document.getElementById('menu_is_custom_fee')?.checked) {
      Array.from(document.getElementsByClassName("custom_fee-element")).forEach(element => element.style.display = "block");
    }
    else {
      Array.from(document.getElementsByClassName("custom_fee-element")).forEach(element => element.style.display = "none");
    }


    if (limit_delivery_area != null) {
      if (limit_delivery_area.checked == true && (isDelivery == true || isDispatch == true)) {
        this.initMap();
        document.getElementById("init_map").click();

        document.getElementById('map-element').style.display = "block";
        document.getElementById('map-canvas').style.height = "500px";
        document.getElementById('info').style.display = "block";
        document.getElementById('info-km').style.display = "block";
        limit_delivery_area_container.style.marginBottom = "0px";
      } else {
        document.getElementById('map-element').style.display = "none";
        document.getElementById('map-canvas').style.height = "0px";
        document.getElementById('info').style.display = "none";
        document.getElementById('info-km').style.display = "none";
      }
    }
  }

  disableFulfilmentsExcept(value, except) {

    var fulfilments = document.getElementsByClassName("fulfilment");

    Array.from(fulfilments).forEach(element => {
        if (element.id != except) {
            if (value)
                element.checked = false;
            element.disabled = value;
        }
    });
  }

  
  
  uncheckFulfilmentsExcept(value, except) {

    var fulfilments = document.getElementsByClassName("fulfilment");

    Array.from(fulfilments).filter(x=> !except.some(e => e == x.id)).forEach(element => {
            if (value)
                element.checked = false;
    });
  }

  initMap() {
    var lat = document.getElementById('menu_latitude').value;
    var lng = document.getElementById('menu_longitude').value;

    function init() {
      // var zoomLevel = document.getElementById('menu_zoom_level').value;
      var mapDiv = document.getElementById('map-canvas');
      map = new google.maps.Map(mapDiv, {
        center: new google.maps.LatLng(lat, lng),
        zoom: 11,
        // zoom: zoomLevel != "" && zoomLevel != null ? parseInt(zoomLevel) : 11,
        mapTypeId: google.maps.MapTypeId.ROADMAP
      });
      distanceWidget = new DistanceWidget(map);

      google.maps.event.addListener(distanceWidget, 'distance_changed', function () {
        displayInfo(distanceWidget);
      });

      google.maps.event.addListener(distanceWidget, 'position_changed', function () {
        displayInfo(distanceWidget);
      });

      google.maps.event.addListener(map, 'bounds_changed', function () {
        document.getElementById('menu_zoom_level').value = map.zoom
      });
    }
    google.maps.event.addDomListener(document.getElementById("init_map"),'click', init);
    // listener = google.maps.event.addDomListener(window, 'load', init);

    /** 
     * A distance widget that will display a circle that can be resized and will 
     * provide the radius in km. 
     * 
     * @param {google.maps.Map} map The map on which to attach the distance widget. 
     * 
     * @constructor 
     */
    function DistanceWidget(map) {
      this.set('map', map);
      this.set('position', map.getCenter());

      var marker = new google.maps.Marker({
        draggable: true,
        icon: {
          url: "/assets/store.png",
          size: new google.maps.Size(50, 50),
          anchor: new google.maps.Point(20, 20)
        },
        title: 'Move me!'
      });

      // Bind the marker map property to the DistanceWidget map property 
      marker.bindTo('map', this);

      // Bind the marker position property to the DistanceWidget position 
      // property 
      marker.bindTo('position', this);

      // Create a new radius widget 
      radiusWidget = new RadiusWidget();

      // Bind the radiusWidget map to the DistanceWidget map 
      radiusWidget.bindTo('map', this);

      // Bind the radiusWidget center to the DistanceWidget position 
      radiusWidget.bindTo('center', this, 'position');

      // Bind to the radiusWidgets' distance property 
      this.bindTo('distance', radiusWidget);

      // Bind to the radiusWidgets' bounds property 
      this.bindTo('bounds', radiusWidget);
    }
    DistanceWidget.prototype = new google.maps.MVCObject();

    /** 
     * A radius widget that add a circle to a map and centers on a marker. 
     * 
     * @constructor 
     */
    function RadiusWidget() {
      var circle = new google.maps.Circle({
        strokeWeight: 1,
        fillColor: '#e89999',
        strokeColor: '#ed0c0c'
      });

      var menuDeliveryArea = document.getElementById('menu_delivery_area').value;

      // Set the distance property value, default to 50km. 
      this.set('distance', menuDeliveryArea != "" && menuDeliveryArea != null ? document.getElementById('menu_delivery_area').value : 5);

      // Bind the RadiusWidget bounds property to the circle bounds property. 
      this.bindTo('bounds', circle);

      // Bind the circle center to the RadiusWidget center property 
      circle.bindTo('center', this);

      // Bind the circle map to the RadiusWidget map 
      circle.bindTo('map', this);

      // Bind the circle radius property to the RadiusWidget radius property 
      circle.bindTo('radius', this);

      this.addSizer_();
    }
    RadiusWidget.prototype = new google.maps.MVCObject();


    /** 
     * Update the radius when the distance has changed. 
     */
    RadiusWidget.prototype.distance_changed = function () {
      this.set('radius', this.get('distance') * 1000);
    };
    /** 
     * Add the sizer marker to the map. 
     * 
     * @private 
     */
    RadiusWidget.prototype.addSizer_ = function () {
      var sizer = new google.maps.Marker({
        draggable: true,
        icon: {
          url: "/assets/resize.png",
          size: new google.maps.Size(40,40),
          anchor: new google.maps.Point(10,12)
        },
        title: 'Drag me!'
      });

      sizer.bindTo('map', this);
      sizer.bindTo('position', this, 'sizer_position');

      var me = this;
      google.maps.event.addListener(sizer, 'drag', function () {
        // Set the circle distance (radius) 
        me.setDistance();
      });
    };

    /** 
     * Update the center of the circle and position the sizer back on the line. 
     * 
     * Position is bound to the DistanceWidget so this is expected to change when 
     * the position of the distance widget is changed. 
     */
    RadiusWidget.prototype.center_changed = function () {
      var bounds = this.get('bounds');

      // Bounds might not always be set so check that it exists first. 
      if (bounds) {
        var lng = bounds.getNorthEast().lng();

        // Put the sizer at center, right on the circle. 
        var position = new google.maps.LatLng(this.get('center').lat(), lng);
        this.set('sizer_position', position);
      }
    };

    /** 
     * Calculates the distance between two latlng locations in km. 
     * @see http://www.movable-type.co.uk/scripts/latlong.html 
     * 
     * @param {google.maps.LatLng} p1 The first lat lng point. 
     * @param {google.maps.LatLng} p2 The second lat lng point. 
     * @return {number} The distance between the two points in km. 
     * @private 
     */
    RadiusWidget.prototype.distanceBetweenPoints_ = function (p1, p2) {
      if (!p1 || !p2) {
        return 0;
      }

      var R = 6371; // Radius of the Earth in km 
      var dLat = (p2.lat() - p1.lat()) * Math.PI / 180;
      var dLon = (p2.lng() - p1.lng()) * Math.PI / 180;
      var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(p1.lat() * Math.PI / 180) * Math.cos(p2.lat() * Math.PI / 180) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      var d = R * c;
      return d;
    };


    /** 
     * Set the distance of the circle based on the position of the sizer. 
     */
    RadiusWidget.prototype.setDistance = function () {
      // As the sizer is being dragged, its position changes.  Because the 
      // RadiusWidget's sizer_position is bound to the sizer's position, it will 
      // change as well. 
      var pos = this.get('sizer_position');
      var center = this.get('center');
      var distance = this.distanceBetweenPoints_(center, pos);

      // Set the distance property for any objects that are bound to it 
      this.set('distance', distance);
    };

    function displayInfo(widget) {
      var info = document.getElementById('info');
      var distance = widget.get('distance');

      if (distance != null && distance != undefined)
        distance = parseFloat(distance).toFixed(2)
        else distance = 5;

      info.innerHTML = 'Position: ' + widget.get('position').toUrlValue(3) + ', Delivery Area: ';
      document.getElementById('menu_latitude').value = widget.get('position').lat();
      document.getElementById('menu_longitude').value = widget.get('position').lng();
      document.getElementById('menu_delivery_area').value = distance;
    }

  }

  initPlacesSearch() {

    var input = document.getElementById('menu_address');
    if (input != null) {
      var autocomplete = new google.maps.places.Autocomplete(input);
      autocomplete.setTypes(["address"]);
      autocomplete.addListener('place_changed', function () {

        var place = autocomplete.getPlace();
        if (!place.geometry) {
          window.alert("Autocomplete's returned place contains no geometry");
          return;
        }

        document.getElementById('menu_address').value = place.formatted_address;

        var lat = place.geometry.location.lat();
        var lng = place.geometry.location.lng();

        document.getElementById('menu_latitude').value = lat;
        document.getElementById('menu_longitude').value = lng;
        document.getElementById('menu_delivery_area').value = 5;

        map.setCenter(new google.maps.LatLng(lat, lng));
        distanceWidget.set('map', map);
        distanceWidget.set('position', map.getCenter());
      });
    }
  }
  limitDeliveryAreaOnChange(event) {

    if (event.target.checked) {
      document.getElementById('map-canvas').style.height = "500px";
      document.getElementById('map-element').style.display = "block";
      document.getElementById('info').style.display = "block";
      document.getElementById('info-km').style.display = "block";
      // document.getElementById('delivery_area_container').style.marginBottom = "0px";
      this.initMap();
      document.getElementById("init_map").click();

    } else {
      document.getElementById('map-canvas').style.height = "0px";
      document.getElementById('info').style.display = "none";
      document.getElementById('info-km').style.display = "none";
      // document.getElementById('delivery_area_container').style.marginBottom = "-25px";
      document.getElementById('map-element').style.display = "none";
    }
  }

  tableServiceOnChange(event){
    if (event.target.checked){
      this.disableFulfilmentsExcept(true, "menu_table_service");
      this.hideDeliveryAreaElements()
    }
    else
      this.disableFulfilmentsExcept(false,"menu_table_service");
  }

  customFulfilmentOnChange(event){

    if (event.target.checked) {
      this.disableFulfilmentsExcept(true, "menu_is_custom_fulfilment");
      Array.from(document.getElementsByClassName("custom_fulfilment-element")).forEach(element => element.style.display = "block");
      
    }
    else{
      this.disableFulfilmentsExcept(false,"menu_is_custom_fulfilment");
      Array.from(document.getElementsByClassName("custom_fulfilment-element")).forEach(element => element.style.display = "none");
      Array.from(document.getElementsByClassName("fulfilment-text")).forEach(element => element.value = null);
    }
  }

  customFeeOnChange(event) {

    if (event.target.checked) {
      Array.from(document.getElementsByClassName("custom_fee-element")).forEach(element => element.style.display = "block");
      document.getElementById("menu_custom_fee").value = "Processing Fee"
      document.getElementById("menu_custom_fee_amount").value = 0

    }
    else {
      Array.from(document.getElementsByClassName("custom-fee-text")).forEach(element => element.value = null);
      Array.from(document.getElementsByClassName("custom_fee-element")).forEach(element => element.style.display = "none");

    }
  }

  dispatchOnChange(event) {

    var limitDeliveryArea = document.getElementById('menu_limit_delivery_area');

    if (event.target.checked) {

      document.getElementById('menu_delivery').checked = false;
      document.getElementById('menu_collection').checked = false;

      if(document.getElementById('menu_limit_delivery_area') != null)
        document.getElementById('delivery_area_container').style.display = "block";
        


      if(limitDeliveryArea?.checked == true){
      document.getElementById('map-canvas').style.height = "500px";
      document.getElementById('map-element').style.display = "block";
      document.getElementById('delivery_area_container').style.display = "block";
      document.getElementById('info').style.display = "block";
      document.getElementById('info-km').style.display = "block";
      this.initMap();
      document.getElementById("init_map").click();
      }

      

    }else{
            
      if(document.getElementById('menu_limit_delivery_area') != null){
      document.getElementById('map-canvas').style.height = "0px";
      document.getElementById('info').style.display = "none";
      document.getElementById('map-element').style.display = "none";
      document.getElementById('info-km').style.display = "none";
      document.getElementById('delivery_area_container').style.display = "none";
      limitDeliveryArea.checked = false

      }
    }
  }

  hideDeliveryAreaElements(){
    var limitDeliveryArea = document.getElementById('menu_limit_delivery_area');

    if(limitDeliveryArea != null){
    document.getElementById('map-canvas').style.height = "0px";
    document.getElementById('info').style.display = "none";
    document.getElementById('map-element').style.display = "none";
    document.getElementById('info-km').style.display = "none";
    document.getElementById('delivery_area_container').style.display = "none";
    limitDeliveryArea.checked = false
    }
  }

  collectionOnChange(event) {

    if (event.target.checked){
      this.hideDeliveryAreaElements();
      this.uncheckFulfilmentsExcept(true, ["menu_delivery", "menu_collection"]);
    }
  }

  deliveryOnChange(event) {

    var limitDeliveryArea = document.getElementById('menu_limit_delivery_area');
      var dispatchField = document.getElementById('menu_dispatch');

    if (event.target.checked) {


      if(document.getElementById('menu_limit_delivery_area') != null)
        document.getElementById('delivery_area_container').style.display = "block";

      if(dispatchField != null)
         dispatchField.checked = false;

      if(limitDeliveryArea?.checked == true){
      document.getElementById('map-canvas').style.height = "500px";
      document.getElementById('map-element').style.display = "block";
      document.getElementById('delivery_area_container').style.display = "block";
      document.getElementById('info').style.display = "block";
      document.getElementById('info-km').style.display = "block";
      this.initMap();
      document.getElementById("init_map").click();
      }

    }else{
            
      if(document.getElementById('menu_limit_delivery_area') != null){
      document.getElementById('map-canvas').style.height = "0px";
      document.getElementById('info').style.display = "none";
      document.getElementById('map-element').style.display = "none";
      document.getElementById('info-km').style.display = "none";
      document.getElementById('delivery_area_container').style.display = "none";
      limitDeliveryArea.checked = false
      }
    }
  }

  changeRadius(event){
    radiusWidget.set('distance', event.target.value);
    distanceWidget.bindTo('bounds', radiusWidget);
    radiusWidget.bindTo('center', distanceWidget, 'position');
    distanceWidget.bindTo('distance', radiusWidget);
    
  }




  change(event) {
    event.preventDefault()

    // If target specifies an index, use that
    if (event.currentTarget.dataset.index) {
      this.index = event.currentTarget.dataset.index

      // If target specifies an index, use that
    } else if (event.currentTarget.dataset.id) {
      this.index = this.tabTargets.findIndex((tab) => tab.id == event.currentTarget.dataset.id)

      // Otherwise, use the index of the current target
    } else {
      this.index = this.tabTargets.indexOf(event.currentTarget)
    }

    window.dispatchEvent(new CustomEvent('tsc:tab-change'))
  }

  all(event) {
    event.preventDefault()

    // If target specifies an index, use that
    if (event.currentTarget.dataset.index) {
      //this.index = event.currentTarget.dataset.index

      // If target specifies an index, use that
    } else if (event.currentTarget.dataset.id) {
      //this.index = this.tabTargets.findIndex((tab) => tab.id == event.currentTarget.dataset.id)

      // Otherwise, use the index of the current target
    } else {
      //this.index = this.tabTargets.indexOf(event.currentTarget)
    }


    this.tabTargets.forEach((tab, index) => {
      const panel = this.panelTargets[index]
      panel.classList.remove('hidden')
    })


    window.dispatchEvent(new CustomEvent('tsc:tab-change'))
  }


  showTab() {
    this.tabTargets.forEach((tab, index) => {
      const panel = this.panelTargets[index]

      if (index === this.index) {
        panel.classList.remove('hidden')
        tab.classList.remove(...this.inactiveTabClasses)
        tab.classList.add(...this.activeTabClasses)

        // Update URL with the tab ID if it has one
        // This will be automatically selected on page load
        if (tab.id) {
          event.preventDefault()
          location.hash = tab.id
        }
      } else {
        panel.classList.add('hidden')
        tab.classList.remove(...this.activeTabClasses)
        tab.classList.add(...this.inactiveTabClasses)
      }
    })
  }

  get index() {
    return parseInt(this.data.get('index') || 0)
  }

  set index(value) {
    this.data.set('index', (value >= 0 ? value : 0))
    this.showTab()
  }

  get anchor() {
    return (document.URL.split('#').length > 1) ? document.URL.split('#')[1] : null;
  }

}